import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // { path: ':id', redirectTo: 'home/:id', pathMatch: 'full' },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: ':courseId', redirectTo: 'home/:courseId', pathMatch: 'full' },
  // { path: 'home/auth', redirectTo: 'home/auth', pathMatch: 'full'},
  { path: 'home/:courseId', redirectTo: 'home/:courseId', pathMatch: 'full' },
  { path: 'home/auth', redirectTo: 'home/auth', pathMatch: 'full'},
  { path: 'auth', redirectTo: 'auth', pathMatch: 'full'},
  { path: 'home/auth/:courseId', redirectTo: 'auth/:cId', pathMatch: 'full' },
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    children: [
      {
        path: '',
        loadChildren: './home/home.module#HomePageModule',
      },
      {
        path: ':courseId',
        loadChildren: './home/home.module#HomePageModule',
      },
      {
        path: 'auth',
        loadChildren: './auth/auth.module#AuthPageModule',
      },
    ],
  },
  { path: 'auth',
    children: [
      {
        path: '',
        loadChildren: './auth/auth.module#AuthPageModule',
      },
      {
        path: ':cId',
        loadChildren: './auth/auth.module#AuthPageModule',
      },
    ],
  },
  /* {
    path: 'caruca-pmt',
    loadChildren: './caruca-pmt/caruca-pmt.module#CarucaPmtPageModule',
  }, */
  {
    path: 'caruca-lbty',
    children: [
     {
       path: '',
        loadChildren: './caruca-lbty/caruca-lbty.module#CarucaLbtyPageModule',
      },
     {
       path: ':email',
       loadChildren: './caruca-lbty/caruca-lbty.module#CarucaLbtyPageModule',
     },
   ],
  },
  {
    path: 'caruca-lbty/caruca-sfty',
    children: [
      {
        path: '',
        loadChildren: './caruca-lbty/caruca-sfty/caruca-sfty.module#CarucaSftyPageModule',
      },
      {
        path: ':email',
        loadChildren: './caruca-lbty/caruca-sfty/caruca-sfty.module#CarucaSftyPageModule',
      },
    ],
  },
  {
    path: 'api',
    loadChildren: './apiExamples/apiExamples.module#ApiExamplesModule',
  },
  {
    path: 'api',
    loadChildren: './apiExamples/apiExamples.module#ApiExamplesModule',
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
